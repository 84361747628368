import { useState, useEffect } from 'react';
import { flow, noop, isEqual, pick, isEmpty } from 'lodash';
import querystring from 'query-string';
// import { SubmissionError } from 'redux-form';
import { useForm, FormProvider } from 'react-hook-form';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { t } from 'i18next';

import { useMediaQuery, Box } from '@mui/material';
import { useTheme, makeStyles } from '@mui/styles';

import { useGlobalContext } from 'src/GlobalContextProvider';
import { useArchitecture } from 'src/pages/Architecture/ArchitectureProvider';

import useLockBodyScroll from 'src/hooks/useLockBodyScroll';
import { isProgramClone } from 'src/common/cloneProgram';

import { getConditionalInputVisibilityFromBlueprint } from 'src/common/conditionals';
import { useSwitchTheme } from 'src/hooks/useSwitchTheme';
import { enqueueSnackbar } from 'src/components/AdmiralSnackBar/actions';
import NavigationBlocker from 'src/components/NavigationBlocker';
import Instrumentation from 'src/instrumentation';
import {
  paymentErrorByBackendDisplayCode,
  genericCardDeclinedError
} from 'src/common/paymentUtils';

import { LOCATIONS_OVERRIDES_BY_ID_NAME } from 'src/pages/Program/ProgramSteps/MultiLocationPublish/utils';
import ProgramPreviewDrawer from './ProgramPreviewDrawer';
import StepSkipper from './ProgramSteps/StepSkipper';
import {
  PROGRAM_FORM_NAME,
  PROGRAM_STEP_NAME_TO_ORDER,
  programActions,
  programErrorTypes,
  programErrorMessageToMatch,
  PROGRAM_STEP_NAMES,
  getProgramTrackingType
} from './Constants';

import { useIsDrawerFullScreen } from './ProgramPreviewDrawer/useIsDrawerFullScreen';
import { DRAWER_FULL_SCREEN_BREAKPOINT } from './ProgramPreviewDrawer/constants';
import ProgramStepper from './ProgramSteps/ProgramStepper';
import useProgram from './utils/useProgram';
import useHandleStepNavigation from './utils/useHandleStepNavigation';
import { getSelectedLocationsMetadata } from './utils/dataFormatters';
import InnerContainer from './components/InnerContainer';

const RIGHT_MARGIN_SPACER = 560;
const DRAWER_POSITION = {
  fixed: 'fixed'
};

const useStyles = makeStyles(theme => {
  const getColumnLayout = spacing => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(spacing)
  });

  const baseContentStyles = {
    flexGrow: 1,
    transition: theme.transitions.create('padding', {
      duration: 0
    })
  };

  const { previewDrawerWidth } = theme.evSizes;

  return {
    content: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(5),
      flexDirection: 'row',
      display: 'flex',
      gap: theme.spacing(7.5),
      ...baseContentStyles,
      [theme.breakpoints.between(0, DRAWER_FULL_SCREEN_BREAKPOINT)]: {
        gap: 0
      },
      [theme.breakpoints.down('md')]: {
        padding: 0
      }
    },
    contentOpen: {
      paddingRight: 0,
      [theme.breakpoints.between(0, DRAWER_FULL_SCREEN_BREAKPOINT)]: {
        paddingRight: `calc(${theme.spacing(3)} + ${previewDrawerWidth}px)`
      }
    },
    contentClosed: {
      paddingRight: theme.spacing(12),
      [theme.breakpoints.down('xl')]: {
        paddingRight: theme.spacing(6)
      },
      [theme.breakpoints.down('md')]: {
        paddingRight: theme.spacing(0)
      }
    },
    stepperRoot: ({ drawerPosition }) => ({
      background: 'inherit',
      padding: 0,
      width: '78%',
      margin: '0 auto',
      marginRight:
        drawerPosition === DRAWER_POSITION.fixed ? RIGHT_MARGIN_SPACER : 0,
      [theme.breakpoints.between(0, DRAWER_FULL_SCREEN_BREAKPOINT)]: {
        marginRight: 'auto'
      },
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'space-between'
      },
      [theme.breakpoints.down('md')]: {
        width: '100%'
      }
    }),
    paper: {
      padding: theme.spacing(2)
    },

    paperV2: ({ drawerPosition }) => ({
      padding: 0,
      marginTop: 0,
      marginRight:
        drawerPosition === DRAWER_POSITION.fixed ? RIGHT_MARGIN_SPACER : 0,
      [theme.breakpoints.between(0, DRAWER_FULL_SCREEN_BREAKPOINT)]: {
        marginRight: 0
      },

      [theme.breakpoints.down('lg')]: {
        padding: theme.spacing(0, 0)
      }
    }),

    step: {
      transition: theme.transitions.create('height', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    stepLabelRoot: {
      cursor: 'pointer',
      textTransform: 'uppercase',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        '& .MuiStepLabel-labelContainer': {
          padding: 0
        }
      }
    },
    stepLabelIconContainer: {
      [theme.breakpoints.down('sm')]: {
        paddingRight: 0,
        paddingBottom: theme.spacing(1)
      }
    },
    previewButton: {
      borderRadius: '0',
      position: 'fixed',
      right: 0
    },
    missingBlueprintSubtitle: {
      color: theme.palette.error.main
    },
    missingBlueprintSubtitleClick: {
      cursor: 'pointer'
    },
    subtitle: {
      height: '28px',
      display: 'flex',
      alignItems: 'center'
    },
    blueprintContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    contentMissing: {
      maxWidth: '500px',
      margin: '0 auto'
    },

    formContainer: {
      ...getColumnLayout(6)
    }
  };
});

const Program = props => {
  const { enqueueSnackbar } = props;

  const {
    programStepper: { selectExactStep, currentStep },
    type,
    blueprints,
    selectedBlueprint,
    handleSelectBlueprint,
    contentName,
    initialValues,
    isAutomated,
    isAutomatedEdit,
    isMultiLocation,
    offersChanged,
    facebook,
    preselectedBusinessObjectIds,
    hasCatalog,
    isContentSelectable,
    blueprintChannels,
    allMlpLocations,
    handleProgramSubmit,
    previewDrawerOpen,
    togglePreviewDrawer,
    setCreativeValidationErrors,
    disableNavigationBlocker
  } = useProgram();
  const architecture = useArchitecture();
  const location = useLocation();
  // const features = useFeatures();
  // const appSettings = useAppSettings();
  const globalContext = useGlobalContext();
  const params = querystring.parse(location.search);

  const { setThemeName, THEME_NAMES } = useSwitchTheme();
  const isDrawerFullScreen = useIsDrawerFullScreen();
  const userMetadataFields = globalContext?.me?.metadata?.fields;
  const isClone = isProgramClone(location.search);

  const [selectedBusinessObjects, setSelectedBusinessObjects] = useState({
    selectedBusinessObjects: [],
    loading:
      isContentSelectable &&
      type !== programActions.automatedEdit &&
      type !== programActions.automatedCreate,
    error: null
  });

  const formMethods = useForm({
    defaultValues: initialValues,
    mode: 'onChange'
  });

  const { reset, handleSubmit, trigger, formState, watch } = formMethods;

  // TODO: decide if we should use this or use 'watch' for form values
  // this doesn't automatically update the form
  const allFormValues = watch();
  const selectedLocationIds = allFormValues?.selectedLocations;

  const selectedLocationsMetadata = getSelectedLocationsMetadata(
    allMlpLocations?.data?.locations,
    selectedLocationIds
  );

  // TODO: resetProgram
  const resetProgram = noop;
  // TODO: figure equivalent to destroy in hookForm
  const destroy = noop;
  const allFormErrors = formState.errors;

  // This only used to manage the loading state for polling/genereating validations
  // I think we can remove this and store the state in here or the ProgramProvider.
  const [isValidatingCreative, setIsValidatingCreative] = useState(null);
  const [isPollingPreview, setIsPollingPreview] = useState(false);

  const conditionalInputsVisibility =
    getConditionalInputVisibilityFromBlueprint(
      selectedBlueprint,
      allFormValues,
      userMetadataFields,
      selectedBusinessObjects?.selectedBusinessObjects,
      selectedLocationsMetadata
    );

  const [drawerPosition, setDrawerPosition] = useState('fixed');

  // User can't scroll body (program form underneath) while the drawer is open and full screen
  useLockBodyScroll(previewDrawerOpen && isDrawerFullScreen);

  // Sets the body background-color to off-white for program and automation checkout forms
  // Sets theme back to default when component unmounts
  useEffect(() => {
    setThemeName(THEME_NAMES.programCreatePage);

    return () => {
      setThemeName(THEME_NAMES.default);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles({
    drawerPosition
  });

  // this tracks progressing through the steps one by one
  // if the step=spend|summary parameter is set
  const [skipStep, setSkipStep] = useState(() => {
    // This conditional ensures that an attempt to deeplink to step 3 (summary) will not crash the form in V3 treatment UI
    if (params.step === PROGRAM_STEP_NAMES.summary) {
      return;
    }
    if (params.step && PROGRAM_STEP_NAME_TO_ORDER[params.step]) {
      return {
        currentStep: 0,
        targetStep: PROGRAM_STEP_NAME_TO_ORDER[params.step],
        // we have to make sure we have tried to pull any missing content before we try and skip steps
        // this is just for pull providers and it sucks
        contentVerified: !(
          isContentSelectable && preselectedBusinessObjectIds.length > 0
        )
      };
    }
  });

  const [orderSuccess, setOrderSuccess] = useState(false);

  const stepTrackingData = {
    architectureId: architecture?.id,
    productId: selectedBlueprint?.id,
    channel: blueprintChannels,
    ...(!isAutomated && { isClone })
  };

  const allConfigureStepErrors = () => {
    return pick(allFormErrors, ['configureStep', 'dynamicUserInputs']);
  };

  const formStepErrors = allConfigureStepErrors();

  const showValidationErrors = () => {
    if (!skipStep) {
      enqueueSnackbar({
        message: t('program:snackbar.formErrors'),
        options: {
          variant: 'error'
        }
      });
    }

    // mark all fields with errors as "touched" so we show the errors
    trigger();
  };

  const { handleNext, lockSubmit } = useHandleStepNavigation({
    skipStep,
    setSkipStep,
    trackingData: stepTrackingData,
    showValidationErrors
  });

  const trackingData = {
    architectureId: architecture?.id,
    productId: selectedBlueprint?.id,
    type: isAutomated ? 'automation' : 'program',
    channel: blueprintChannels
  };

  const hasLocationsOverrides = !isEmpty(watch(LOCATIONS_OVERRIDES_BY_ID_NAME));

  const handleStepChange = step => {
    if (step === currentStep) {
      return;
    }
    if (step < currentStep) {
      trigger().then(isValid => {
        if (isValid) {
          selectExactStep(step, trackingData);
        }
      });
    } else {
      trigger().then(isValid => {
        // don't allow the user to skip steps if they have errors or have overrides b/c they need to
        // use the proceed button to trigger the apply modal
        if (isValid && !hasLocationsOverrides) {
          handleNext({ currentStep, formStepErrors });
        }
      });
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  // const upToSmall = useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    // component will mount
    // Note We need to reset() redux form on mount because we have set destroyOnUnmount:false to
    //      preserve the form state for the stepper. This will allow form elements in each step to
    //      mount in and out and maintain their state.
    reset();

    // reset program back to initial state
    resetProgram();

    if (isMobile && previewDrawerOpen) {
      togglePreviewDrawer();
    }

    if (isAutomated) {
      Instrumentation.logEvent('program-automation-load');
    } else {
      Instrumentation.logEvent('program-load', {
        isClone,
        type: getProgramTrackingType({ isMultiLocation })
      });
    }

    // compunent will unmount
    return () => {
      destroy();
      // reset program back to initial state
      resetProgram();
    };
  }, []);

  const handleSubmitHandler = data => {
    return handleProgramSubmit(data, {
      architecture,
      selectedBlueprint,
      selectedBusinessObjects,
      userMetadataFields
    })
      .then(({ afterSuccess }) => {
        // we have to set the form to success before we can navigate etc.
        setOrderSuccess(true);
        setTimeout(() => {
          afterSuccess();
        }, 0);
      })
      .catch(({ error, postErrorMessage }) => {
        setIsPollingPreview(false);
        setIsValidatingCreative(false);
        const errorName = error?.graphQLErrors?.[0]?.extensions?.errorName;
        const errorDisplayCode =
          error?.graphQLErrors?.[0]?.extensions?.additionalExceptionDetails
            ?.displayCode;

        // if card declined / billing error
        if (
          errorName === programErrorTypes.billingException ||
          errorName === programErrorTypes.paymentAuthorizationException
        ) {
          const errorMessage =
            paymentErrorByBackendDisplayCode(errorDisplayCode) ||
            genericCardDeclinedError;

          enqueueSnackbar({
            message: errorMessage,
            options: {
              variant: 'error'
            }
          });

          // set a submission error that we look for on the payment selection component
          // TODO: how do we do this in hook form?
          // throw new SubmissionError({
          //   paymentMethodId: errorMessage
          // });
        }

        // validation errors
        if (
          error?.graphQLErrors?.[0]?.extensions?.errorName ===
          programErrorTypes?.validationException
        ) {
          // if start date is before today
          if (
            error?.graphQLErrors?.[0]?.message &&
            error?.graphQLErrors?.[0]?.message.match(
              programErrorMessageToMatch.startDateBeforeToday
            )
          ) {
            enqueueSnackbar({
              message: t('programCreate:snackbar.invalidStartDate'),
              options: {
                variant: 'error'
              }
            });

            // set a submission error on startDate
            // TODO: how do we do this 2 in hook form?
            // throw new SubmissionError({
            //   spendStep: {
            //     startDate: t(
            //       'programCreate:snackbar.invalidStartDateSubmissionError'
            //     )
            //   }
            // });
          }
        }

        enqueueSnackbar({
          message:
            postErrorMessage || t('programCreate:snackbar.submitErrorGeneric'),
          options: {
            variant: 'error'
          }
        });
      });
  };

  // we need to remove the fixSyncErrorInputNameFilters from the form values so it does not trigger the navigation blocker
  const dirtyCheckFormValues = allFormValues;
  delete dirtyCheckFormValues?.fixSyncErrorInputNameFilters;

  const isAutomatedDirty =
    isAutomated && !isEqual(initialValues, dirtyCheckFormValues);

  // Warn the user when they navigate away from the page only if the form
  // has been changed (i.e. keep them from throwing away order work
  // they've done on accident.
  // Note: Once the user has placed the order, the form stays in a dirty
  //       state so we override this variable to always be false if we
  //       have a successful order.
  let shouldBlockNavigation = isAutomated
    ? isAutomatedDirty
    : formState.isDirty;

  if (disableNavigationBlocker || orderSuccess) {
    shouldBlockNavigation = false;
  }

  const submitForm = () => {
    handleSubmit(handleSubmitHandler)();
  };

  const programStepProps = {
    architecture,
    architectureHasCatalog: hasCatalog,
    blueprints,
    contentName,
    facebook,
    formName: PROGRAM_FORM_NAME,
    initialValues,
    isAutomated,
    isAutomatedEdit,
    isContentSelectable,
    selectedBlueprint,
    handleSelectBlueprint,
    selectedBusinessObjects,
    setSelectedBusinessObjects,
    type,
    skipStep,
    setSkipStep,
    submitForm,
    handleNext,
    lockSubmit,
    showValidationErrors,
    conditionalInputsVisibility,
    hookFormMethods: formMethods,
    isHookForm: true,
    isChannelValidationLoading: isPollingPreview || isValidatingCreative,
    isPollingPreview,
    setIsPollingPreview,
    selectedLocationsMetadata,
    ...(currentStep === PROGRAM_STEP_NAME_TO_ORDER.spend && {
      offersChanged
    }),
    setIsValidatingCreative,
    isMultiLocation
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        paddingTop: theme.spacing(3)
      }}
      data-cy={type}
    >
      <NavigationBlocker block={shouldBlockNavigation} />
      <InnerContainer
        previewDrawerOpen={previewDrawerOpen}
        data-cy="innnercontainer"
      >
        <StepSkipper skipStep={skipStep} setSkipStep={setSkipStep}>
          <FormProvider {...formMethods}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
              }}
            >
              <form
                style={{ width: '100%' }}
                onSubmit={handleSubmit(handleSubmitHandler)}
              >
                <ProgramStepper
                  isMobile={isMobile}
                  classes={classes} // remove
                  programStepProps={programStepProps}
                  handleStepChange={handleStepChange}
                  drawerPosition={drawerPosition}
                />
              </form>
            </Box>
            <ProgramPreviewDrawer
              contentName={contentName}
              selectedBusinessObjects={selectedBusinessObjects}
              defaultFacebookPage={facebook?.getSelectedFacebookPage(
                allFormValues
              )}
              blueprintsLoading={blueprints?.loading}
              conditionalInputsVisibility={conditionalInputsVisibility}
              facebook={facebook}
              drawerPosition={drawerPosition}
              setDrawerPosition={setDrawerPosition}
              isHookForm
              setIsPollingPreview={setIsPollingPreview}
              isPollingPreview={isPollingPreview}
              setIsValidatingCreative={setIsValidatingCreative}
              formValues={allFormValues}
              setCreativeValidationErrors={setCreativeValidationErrors}
            />
          </FormProvider>
        </StepSkipper>
      </InnerContainer>
    </Box>
  );
};

export default flow(
  connect(null, {
    enqueueSnackbar
  })
)(Program);
